/**
 * Adds the collapse class to the card to make it collapsible
 * @param {jQuery} $card jQuery card object
 */
function makeCardCollapsible($card) {
    let $cardBody = $card.find('.card-body');
    let $bodyParent = $cardBody.parent();

    if (!$bodyParent.hasClass('collapse')) {
        $bodyParent.addClass('collapse');
    }
}

/**
 * Collapses the card
 * @param {jQuery} $card jQuery card object
 */
function toggleCollapseCard($card) {
    let $cardBody = $card.find('.card-body');
    let $bodyParent = $cardBody.parent();
    $bodyParent.addClass('show');
    $card.find('.card-header a').attr('aria-expanded', true);
}

/**
 * Adds the unique of the card container to the cards and collapses the first card if needed
 */
function initializeCardContainers() {
    $('.card-container').each(function () {
        let $currentContainer = $(this);
        const isCollapsible = $currentContainer.data('collapsible');
        const shouldOpenFirst = $currentContainer.data('openFirst');
        let $cards = $currentContainer.find('.card');

        $cards.each(function (index) {
            let $card = $(this);
            let $parentElements = $card.find('[data-parent]');

            // Make collapsible.
            if (isCollapsible) {
                makeCardCollapsible($card);

                if (shouldOpenFirst && index === 0) {
                    toggleCollapseCard($card);
                }
            } else {
                let link = $card.find('.card-header a');
                link.removeAttr('data-toggle');
                link.removeAttr('href');
            }

            // Link to container.
            $parentElements.each(function () {
                $(this).attr('data-parent', `#${$currentContainer.attr('id')}`);
            });
        });
    });
}

module.exports = {
    init: function () {
        initializeCardContainers();
    }
};
