'use strict';

var debounce = require('lodash/debounce');
const responsive = require('../util/responsive');
let secondAndThirdLevelLoaded = false;
let secondAndThirdLevelLoading = false;

module.exports = function () {
    /**
     * Calcuclate menu items
     * @param {string} clickedFirstLevel - clickedFirstLevel
    */
    function calculateMenuItems(clickedFirstLevel) {
        const $subElements = $('.second-lvl li:not(.js-show-more-menu)[data-first-lvl=' + clickedFirstLevel + ']');

        if (responsive.isTabletOrSmaller()) {
            $('.js-second-lvl').css('max-height', '');
            $('.js-second-lvl .content-asset').css('max-height', '');
            $subElements.addClass('d-block');
            $('.second-lvl li.js-show-more-menu[data-first-lvl=' + clickedFirstLevel + ']').removeClass('d-block');
        } else {
            var $secondLevelHeight = $('.js-second-lvl').height();
            $('.js-second-lvl').css('max-height', $secondLevelHeight + 'px');
            $('.js-second-lvl .content-asset').css('max-height', $secondLevelHeight + 'px');

            var containerWidth = $('.js-second-lvl').width();
            var containerOffset = $('.js-second-lvl').offset().left;
            var hideCategory;

            $subElements.each(function () {
                var elementOffset = $(this).offset().left;
                hideCategory = (elementOffset - containerOffset) > (containerWidth - 50); // 50 buffer
                $(this).toggleClass('d-block', !hideCategory);
            });

            if (hideCategory) {
                $('.second-lvl li.d-block[data-first-lvl=' + clickedFirstLevel + ']').last().removeClass('d-block');
                $('.second-lvl li.js-show-more-menu[data-first-lvl=' + clickedFirstLevel + ']').addClass('d-block');
            }
        }
    }


    /** Open first level
     * @param {Jquery} clickedMenuItem instance of the clicked menu item
     * @param {Event} e the click or mouseenter event
    */
    function openFirstLevel(clickedMenuItem, e) {
        $('.third-lvl li').removeClass('d-inline-block');
        $('.second-lvl li').removeClass('d-block');
        $('.js-menu-heading').removeClass('d-block');
        $('.header-menu-item').removeClass('active');

        const clickedFirstLevel = clickedMenuItem.data('first-lvl');
        if (clickedFirstLevel) {
            $('.second-lvl li:not(.js-show-more-menu)[data-first-lvl=' + clickedFirstLevel + ']').addClass('d-block');
            $('.nav-fly-out').removeClass('d-none');
            calculateMenuItems(clickedFirstLevel);
            $('.js-menu-heading[data-first-lvl=' + clickedFirstLevel + ']').addClass('d-block');
            clickedMenuItem.addClass('active');
            $('.second-lvl').addClass('open');
            $('.js-second-lvl .content-asset').each(function () {
                $(this).toggleClass('d-none', !$(this).children().hasClass('d-block'));
            });
            e.preventDefault();
        }
    }

    /** Load second and third level of menu
     * @param {Jquery} clickedMenuItem instance of the clicked menu item
     * @param {Event} e the click or mouseenter event
    */
    function loadSecondAndThirdLevel(clickedMenuItem, e) {
        const $secondLevelContainer = $('.js-second-lvl');
        const $thirdLevelContainer = $('.js-third-lvl');

        const url = $secondLevelContainer.attr('data-get-menu-items-url');

        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                if (data && data.secondLevel) {
                    data.secondLevel.forEach((item) => {
                        $secondLevelContainer.append(item);
                    });
                }
                if (data && data.thirdLevel) {
                    data.thirdLevel.forEach((item) => {
                        $thirdLevelContainer.append(item);
                    });
                }

                openFirstLevel(clickedMenuItem, e);
                secondAndThirdLevelLoaded = true;
                secondAndThirdLevelLoading = false;
            },
            error: function () {
                // DO NOTHING
                secondAndThirdLevelLoading = false;
            }
        });
    }

    /** Checks if the menu is loaded
     * @param {Event} e the click or mouseenter event
    */
    function checkIfMenuIsLoaded(e) {
        e.preventDefault();

        const clickedMenuItem = $(this).closest('.header-menu-item');
        if (secondAndThirdLevelLoading) {
            return;
        }

        if (secondAndThirdLevelLoaded) {
            openFirstLevel(clickedMenuItem, e);
        } else {
            secondAndThirdLevelLoading = true;
            loadSecondAndThirdLevel(clickedMenuItem, e);
        }
    }

    /**
     * Calculate header height
    */
    function calculateHeaderHeight() {
        if (responsive.isTabletOrSmaller()) {
            const topContentHeight = $('.js-top-container').outerHeight() - $(window).scrollTop();
            const headerHeight = $('header').height() + (topContentHeight < 0 ? 0 : topContentHeight);
            $('.first-lvl, .second-lvl, .third-lvl').css({ height: 'calc(100vh - ' + headerHeight + 'px)', top: headerHeight + 'px' });
        }
    }

    /**
     * Bind events
    */
    function bindEvents() {
        $('.first-lvl li').off('mouseenter');
        $(document).on('click', '.first-lvl li > a > i', checkIfMenuIsLoaded);

        if (!responsive.isTabletOrSmaller()) {
            $(document).on('mouseenter', '.first-lvl li', checkIfMenuIsLoaded);

            // Resetting header height
            $('.first-lvl, .second-lvl, .third-lvl-lvl').css({ height: '100%' });
        }
    }

    $(window).on('resize', debounce(function () {
        bindEvents();
        calculateHeaderHeight();
    }));

    bindEvents();
    calculateHeaderHeight();

    /** Open second level
     * @param {Event} d the click or mouseenter event
    */
    function opensecondlvl(d) {
        $('.third-lvl li').removeClass('d-inline-block');
        $('.third-lvl li[data-second-lvl=' + $(this).data('second-lvl') + ']').addClass('d-inline-block');

        // Mobile only
        if ($(this).data('second-lvl')) {
            $('.third-lvl').addClass('open');
            d.preventDefault();
        }
    }

    if ($(window).width() < 1024) {
        $(document).on('click', '.second-lvl li > a > i', opensecondlvl);
    }

    /** Close the menu and flyout */
    function closeMenu() {
        $('.menu').removeClass('open');
        $('.second-lvl li').removeClass('d-block');
        $('.js-menu-heading').removeClass('d-block');
        $('.nav-fly-out').addClass('d-none');
        $('.lvl').removeClass('open');
        $('.js-menu-button').removeClass('active');
        $('body').removeClass('no-scroll');
    }

    /** open the menu and flyout */
    function openMenu() {
        $('.menu').addClass('open');
        $('.first-lvl').addClass('open');
        $('.js-menu-button').addClass('active');

        if (responsive.isTabletOrSmaller()) {
            setTimeout(function () {
                $('body').delay(400).addClass('no-scroll');
            }, 400);
        }
    }

    /**
     * Checks if the menu is open
     * @returns {boolean} menu is open?
    */
    function menuIsOpen() {
        return $('.menu').hasClass('open');
    }

    // MOBILE GO BACK TO PARENT MENU
    $(document).on('click', '.js-back', function () {
        $(this).closest('.lvl').removeClass('open');
    });

    // ON CLICK MENU BUTTON
    $(document).on('click', '.js-menu-button', function () {
        if (!menuIsOpen()) {
            openMenu();
        } else {
            closeMenu();
        }
    });

    // ON MOUSE ENTER
    $(document).on('mouseenter', '.js-menu-button', function (e) {
        if (!menuIsOpen() && !responsive.isTabletOrSmaller() && e.relatedTarget) {
            openMenu();
        }
    });

    // ON MOUSE LEAVE
    $(document).on('mouseleave', '.js-menu-button, .js-menu', function (e) {
        var target = e.toElement || e.relatedTarget;
        if (menuIsOpen() && !responsive.isTabletOrSmaller() && !$(target).parents('.js-menu').length) {
            closeMenu();
        }
    });

    // OPEN MENU
    $(document).on('click', '.js-close', function () {
        closeMenu();
    });

    $(window).on('scroll', debounce(function () {
        calculateHeaderHeight();

        if (menuIsOpen()) {
            closeMenu();
        }
    }));

    if (responsive.isMobile()) {
        $('.blog-menu .sub-menu ul').hide();
        $('.blog-menu .fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        $('.sub-menu').click(function (e) {
            e.stopPropagation();
            const $parentLI = $(this).closest('li');
            const $other = $parentLI.siblings();
            const $myUL = $parentLI.find('ul');
            const $myToggle = $(this).find('.fas');
            $other.find('ul').slideUp('100');
            $other.find('.fas').removeClass('fa-chevron-up').addClass('fa-chevron-down');
            $myUL.slideToggle('100');
            $myToggle.toggleClass('fa-chevron-up fa-chevron-down');
        });
    } else {
        $('.blog-menu i').hide();
    }

    $('.js-store-menu').on('click', function (e) {
        if (responsive.isMobile()) {
            e.preventDefault();
            window.location.href = $(this).data('mobile-url');
        }
    });

    $('.refinements-categories a[data-toggle="collapse"]').on('click', function () {
        var $icon = $(this).find('i');
        if ($icon.hasClass('fa-chevron-down')) {
            $icon.removeClass('fa-chevron-down').addClass('fa-chevron-up');
        } else {
            $icon.removeClass('fa-chevron-up').addClass('fa-chevron-down');
        }
    });

    /* $('.js-store-menu').on('click', function (e) {
        if (responsive.isMobile()) {
            e.preventDefault();
            closeMenu();
            const topContentHeight = $('.js-top-container').outerHeight() - $(window).scrollTop();
            const headerHeight = $('header').height() + (topContentHeight < 0 ? 0 : topContentHeight);
            $('.store-menu').css({ height: '100vh', width: '100vw', top: headerHeight + 'px' });
            $('.store-menu').addClass('open');
            $('body').addClass('no-scroll');
        }
    });
    $('.store-menu .js-close').on('click', function () {
        $('.store-menu').removeClass('open');
        $('.store-menu').css({ height: '0px', width: '0px' });
        $('body').removeClass('no-scroll');
    }); */
};
