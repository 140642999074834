'use strict';
/* const appendToUrl = require('../util/appendToUrl');

var previousEmail; */

/**
 * Test input value with regex
 * @param {Event} e the event, used to get the text when not text is entered
 * @param {string} regex the regex to compare
 * @param {string} text the text to compare
 * @return {boolean} if test was correct
 */
function testRegex(e, regex, text) {
    var preInput = $(this).val() || '';
    var input = preInput + e.type === 'paste' ?
        e.originalEvent.clipboardData.getData('text') :
        String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!regex.test(text || input)) {
        e.preventDefault();
        return false;
    }
    return true;
}
/**
 * test email input field
 * @param {Event} e the event, used to get the text when not text is entered
 * @returns {boolean} returns false
 */
/* function validateEmail(e) {
    var url = $('#chatHelper').data('validateEmailUrl');
    var $this = $('#SuppliedEmail');
    var email = $this.val();
    var $wrapper = $this.parent();
    if (!email || email === '' || email === previousEmail) {
        return false;
    }
    previousEmail = email;

    let urlParams = {
        email: email
    };

    url = appendToUrl(url, urlParams);

    if (!$this.siblings('.invalid-feedback').length) {
        $this.after('<div class="invalid-feedback"></div>');
    }

    return $.ajax({
        url: url,
        type: 'get',
        success: function (data) {
            if (!data.valid) {
                e.preventDefault();
                $('.embeddedServiceSidebarButton').prop('disabled', true);
                $this.closest('form').find(':submit').attr('disabled', 'disabled');
                $wrapper.addClass('has-error');
                $this.addClass('is-invalid');

                if (data.message) {
                    $wrapper.find('.invalid-feedback').html(data.message);
                    $wrapper.find('.invalid-feedback').show();
                }
            } else {
                $wrapper.removeClass('has-error');
                $('.embeddedServiceSidebarButton').prop('disabled', false);
                $this.closest('form').find(':submit').removeAttr('disabled');
                $wrapper.find('.invalid-feedback').remove();
                return true;
            }
            return false;
        },
        error: function () {
            $wrapper.find('.invalid-feedback').remove();
            $this.closest('form').find(':submit').removeAttr('disabled');
            return false;
        }
    });
} */

var exports = {
    NameFieldValidation: function () {
        $(document).on('keypress paste', '.embeddedServiceSidebarForm input.SuppliedName, .embeddedServiceSidebarForm input.Contact_Name__c, .embeddedServiceSidebarForm input.FirstName, .embeddedServiceSidebarForm input.LastName', function (e) {
            var regex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/g;
            testRegex(e, regex);
        });
    },
    PhoneFieldValidation: function () {
        $(document).on('keypress paste', '.embeddedServiceSidebarForm input.SuppliedPhone', function (e) {
            // eslint-disable-next-line no-useless-escape
            var regex = /^[0-9\.-\/]+/g;
            testRegex(e, regex);
        });
        /* $(document).on('blur', '.embeddedServiceSidebarForm input.SuppliedPhone, .embeddedServiceSidebarForm input.submitButton', function (e) {
            var regex = /^(((32|0032)4[6-9][0-9]{7})|(04[6-9][0-9]{7}))|((32|0032)([1-9][0-9]{7})|(0[1-9][0-9]?[0-9]{7}))$/g;
            var $this = $('#SuppliedPhone');
            var $wrapper = $this.parent();
            if (testRegex(e, regex, $this.val())) {
                $wrapper.find('.invalid-feedback').remove();
                $wrapper.removeClass('has-error');
                $('.embeddedServiceSidebarButton').prop('disabled', false);
            } else {
                if (!$this.siblings('.invalid-feedback').length) {
                    $this.after('<div class="invalid-feedback"></div>');
                }
                $wrapper.addClass('has-error');
                $this.addClass('is-invalid');
                $wrapper.find('.invalid-feedback').html($('#chatHelper').data('phoneError'));
                $wrapper.find('.invalid-feedback').show();

                $('.embeddedServiceSidebarButton').prop('disabled', true);
            }
        }); */
    },
    emailFieldValidation: function () {
        /* $(document).on('blur', '.embeddedServiceSidebarForm input.SuppliedName, .embeddedServiceSidebarForm input.Contact_Name__c, .embeddedServiceSidebarForm input.FirstName, .embeddedServiceSidebarForm input.LastName, .embeddedServiceSidebarForm input.SuppliedPhone, .embeddedServiceSidebarForm input.Subject, .embeddedServiceSidebarForm input.SuppliedEmail', function (e) {
            validateEmail(e);
        });
        $(document).on('click', '.embeddedServiceSidebarForm button.submitButton', function (e) {
            validateEmail(e);
        }); */
    }
};

module.exports = exports;
