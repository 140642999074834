'use strict';

const Slider = {};
const sliderConfig = require('../config/sliderConfig.json');
const sliderInstance = require('keen-slider').default;

/**
 * Add arrows
 * @param {Object} instance - Keenslider instance
 * @param {Object} $carouselWrapper - DOM element for the carousel
 * @param {Object} config - Config object
 */
Slider.addArrows = function (instance, $carouselWrapper, config) {
    if (!instance.track.details) {
        return;
    }

    const amountOfSlides = instance.track.details.slides.length;
    const perView = instance.options.slides ? instance.options.slides.perView : 1;
    if (amountOfSlides <= perView || $carouselWrapper.find('.arrows-container').length) {
        return;
    }

    $carouselWrapper.append("<div class='arrows-container'><i class='arrow arrow--left fas fa-angle-left js-arrow-left'></i>"
        + "<i class='arrow arrow--right fas fa-angle-right js-arrow-right'></i></div>");
    Slider.changeArrowsOffset($carouselWrapper, config);

    let $leftArrow = $carouselWrapper.find('.js-arrow-left');
    let $rightArrow = $carouselWrapper.find('.js-arrow-right');

    $leftArrow.on('click', (e) => {
        e.preventDefault();
        instance.prev();
    });
    $rightArrow.on('click', (e) => {
        e.preventDefault();
        instance.next();
    });
};

/**
 * Add dots
 * @param {Object} instance - Keenslider instance
 * @param {Object} $carouselWrapper - DOM element for the carousel
 */
Slider.addDots = function (instance, $carouselWrapper) {
    if (!instance.track.details) {
        return;
    }
    const amountOfSlides = instance.track.details.slides.length;
    const perView = instance.options.slides ? instance.options.slides.perView : 1;
    if (amountOfSlides <= perView) {
        return;
    }

    const $dotsWrapper = $("<div class='dots-container js-dots'></div>");
    if (!$carouselWrapper.find('.js-dots').length) {
        $carouselWrapper.append($dotsWrapper);
    }

    $dotsWrapper.empty();

    instance.track.details.slides.forEach((_e, idx) => {
        const $dot = $('<button></button>')
            .addClass('js-dot')
            .addClass('dot')
            .appendTo($dotsWrapper);

        $dot.on('click', () => {
            instance.moveToIdx(idx);
        });
    });
};

/**
 * Adds disabled class to arrows
 * @param {Object} $carouselWrapper - DOM element for the carousel
 * @param {Object} config - config object
*/
Slider.changeArrowsOffset = function ($carouselWrapper, config) {
    /**
     * Sets the offset of an arrow.
     * @param {jQuery} $arrow The jQuery arrow object.
     * @param {number} arrowOffset The offset.
     * @param {string} arrowOffsetDirection The direction.
     */
    function setOffset($arrow, arrowOffset, arrowOffsetDirection) {
        if ($arrow.length > 0) {
            const currentOffset = $arrow.offset().top;

            if (arrowOffsetDirection === '+') {
                $arrow.offset({ top: currentOffset + arrowOffset });
            } else {
                $arrow.offset({ top: currentOffset - arrowOffset });
            }
        }
    }

    if (config && config.arrowoffset && config.arrowoffsetDirection) {
        const $arrowsContainer = $($carouselWrapper).find('.arrows-container');

        if ($arrowsContainer.length > 0) {
            let $leftArrow = $arrowsContainer.find('.arrow.arrow--left');
            let $rightArrow = $arrowsContainer.find('.arrow.arrow--right');

            setOffset($leftArrow, config.arrowoffset, config.arrowoffsetDirection);
            setOffset($rightArrow, config.arrowoffset, config.arrowoffsetDirection);
        }
    }
};

/**
 * Reads the carousel wrapper element data to find slider configuration.
 * @param {jQuery} $carouselWrapper The carousel wrapper jquery object.
 * @param {Object} config The config object that needs to be overridden.
 * @return {Object} The configuration object overrides.
 */
Slider.getCarouselWrapperConfig = function ($carouselWrapper, config) {
    /* eslint no-param-reassign: off */
    if (!$carouselWrapper) {
        return config;
    }

    if ($carouselWrapper.data('enable-horizontal-scrollbar')) {
        config.enablehorizontalscrollbar = $carouselWrapper.data('enable-horizontal-scrollbar');
        config.loop = false;
    }

    if ($carouselWrapper.data('handle-custom-color')) {
        config.handlecustomcolor = $carouselWrapper.data('handle-custom-color');
    }

    if ($carouselWrapper.data('item-offset')) {
        config.slides = {
            spacing: $carouselWrapper.data('item-offset')
        };
    }

    if ($carouselWrapper.data('arrow-offset')) {
        config.arrowoffset = $carouselWrapper.data('arrow-offset');
    }

    if ($carouselWrapper.data('arrow-offset-direction')) {
        config.arrowoffsetdirection = $carouselWrapper.data('arrow-offset-direction');
    }

    if (!config.breakpoints) {
        config.breakpoints = {};
    }

    if ($carouselWrapper.data('xs')) {
        config.breakpoints['(min-width: 0px)'] = {
            slides: {
                perView: $carouselWrapper.data('xs'),
                spacing: $carouselWrapper.data('xsSpacing')
            }
        };
    }

    if ($carouselWrapper.data('sm')) {
        config.breakpoints['(min-width: 600px)'] = {
            slides: {
                perView: $carouselWrapper.data('sm'),
                spacing: $carouselWrapper.data('smSpacing')
            }
        };
    }

    if ($carouselWrapper.data('md')) {
        config.breakpoints['(min-width: 1200px)'] = {
            slides: {
                perView: $carouselWrapper.data('md'),
                spacing: $carouselWrapper.data('mdSpacing')
            }
        };
    }

    return config;
};

/**
 * Adds disabled class to arrows
 * @param {Object} instance - Keenslider instance
 * @param {Object} $carouselWrapper - DOM element for the carousel
 * @param {Object} config - config object
*/
Slider.updateClasses = function (instance, $carouselWrapper, config) {
    if (!instance.track.details) {
        return;
    }

    const slide = instance.track.details.rel;
    const amountOfSlides = instance.track.details.slides.length;
    const slidesPerView = instance.options.slides ? instance.options.slides.perView : 1;
    const $leftArrow = $carouselWrapper.find('.js-arrow-left');
    const $rightArrow = $carouselWrapper.find('.js-arrow-right');
    const $dotsWrapper = $carouselWrapper.find('.js-dots');
    const ARROW_DISABLED = 'arrow--disabled';

    $dotsWrapper.find('.js-dot').each(function (idx) {
        if (idx === slide) {
            $(this).addClass('dot--active');
        } else {
            $(this).removeClass('dot--active');
        }
    });

    if (config.loop) {
        $leftArrow.removeClass(ARROW_DISABLED);
        $rightArrow.removeClass(ARROW_DISABLED);
        return;
    }

    if (slide === 0) {
        $leftArrow.addClass(ARROW_DISABLED);
    } else {
        $leftArrow.removeClass(ARROW_DISABLED);
    }

    if (slide === amountOfSlides - 1) {
        $rightArrow.addClass(ARROW_DISABLED);
    } else {
        $rightArrow.removeClass(ARROW_DISABLED);
    }

    if (amountOfSlides < slidesPerView) {
        $leftArrow.addClass(ARROW_DISABLED);
        $rightArrow.addClass(ARROW_DISABLED);
    }
};

Slider.initHorizontalScrollbar = function ($carouselWrapper, config) {
    if (!config.enablehorizontalscrollbar) {
        return;
    }

    const $carousel = $carouselWrapper.find('.keen-slider');
    let isDown = false;
    let startX;
    let scrollLeft;

    $carousel.on('mousedown', (e) => {
        isDown = true;
        $carousel.addClass('active');
        startX = e.pageX - $carousel.offset().left;
        scrollLeft = $carousel.offset().left;
    });

    $carousel.on('mouseleave', () => {
        isDown = false;
        $carousel.removeClass('active');
    });

    $carousel.on('mouseup', () => {
        isDown = false;
        $carousel.removeClass('active');
    });

    $carousel.on('mousemove', (e) => {
        if (!isDown) {
            return;
        }
        e.preventDefault();
        const x = e.pageX - $carousel.offset().left;
        const walk = (x - startX) * 3;
        $carousel[0].scrollLeft = scrollLeft - walk;
    });
};

/**
 * Inits the mutation observer
 * @param {Object} instance - Keenslider instance
 * @param {Object} $carouselWrapper - DOM element for the carousel
 * @param {Object} config - Config object
 */
Slider.initMutationObserver = (instance, $carouselWrapper, config) => {
    const observer = new MutationObserver(() => {
        instance.update();
        Slider.addArrows(instance, $carouselWrapper, config);
        Slider.addDots(instance, $carouselWrapper);
    });
    observer.observe($carouselWrapper[0], {
        childList: true,
        subtree: true
    });
};

/**
 * Adds disabled class to arrows
 * @param {Object} $carouselWrapper - DOM element for the carousel
 * @param {Object} config - config object
 *
*/
Slider.initCarousel = function ($carouselWrapper, config) {
    let carouselInstance;
    let interval;

    const $carousel = $carouselWrapper.find('.keen-slider');
    const scrollDelay = $carouselWrapper.data('scroll-delay') || 5000;
    const slidesToShow = $carouselWrapper.data('slides-to-show');

    // Fix for page designer pages
    $carousel.children('.experience-component').addClass('keen-slider__slide');

    // Set amount of slides to show
    let $sliders = $carouselWrapper.find('.keen-slider__slide');
    const slidesToRemove = $sliders.length - slidesToShow;
    if (slidesToShow < $sliders.length) {
        $sliders.slice(-slidesToRemove).remove();
    }

    config = Slider.getCarouselWrapperConfig($carouselWrapper, config);

    /**
     * Auto plays the slider
     * @param {boolean} run run
     */
    function autoplay(run) {
        if (!carouselInstance) {
            return;
        }

        const amountOfSlides = carouselInstance.track.details.slides.length;
        const perView = carouselInstance.options.slides ? carouselInstance.options.slides.perView : 1;
        if (amountOfSlides <= perView) {
            return;
        }

        clearInterval(interval);
        interval = setInterval(() => { // eslint-disable-line
            if (run && carouselInstance) {
                carouselInstance.next();
            }
        }, scrollDelay);
    }

    var functionsConfig = {
        created: function (instance) {
            Slider.addArrows(instance, $carouselWrapper, config);
            Slider.addDots(instance, $carouselWrapper);
            Slider.initHorizontalScrollbar($carouselWrapper, config);
            Slider.updateClasses(instance, $carouselWrapper, config);
            Slider.initMutationObserver(instance, $carouselWrapper, config);
        },
        dragStarted: () => {
            if (config.autoplay) {
                autoplay(false);
            }
        },
        dragEnd: () => {
            if (config.autoplay) {
                interval = autoplay(true);
            }
        },
        slideChanged: function (instance) {
            Slider.updateClasses(instance, $carouselWrapper, config);
        }
    };

    Object.assign(config, functionsConfig);

    // eslint-disable-next-line no-new
    carouselInstance = sliderInstance($carousel[0], config);

    if ($carousel.hasClass('carousel-four') || $carousel.hasClass('carousel-blog')) {
        carouselInstance.refresh();
    }

    if (config.autoplay) {
        $carousel.on('mouseover', () => {
            interval = autoplay(false);
        });
        $carousel.on('mouseout', () => {
            autoplay(true);
        });

        autoplay(true);
    }
};

/**
 * Initialize carousels
 */
Slider.initCarousels = () => {
    Object.keys(sliderConfig).forEach(selector => {
        $(selector).each(function () {
            let config = sliderConfig[selector];
            Slider.initCarousel($(this), config);
        });
    });
};

Slider.initMainBannerListener = () => {
    $(document).on('click', '.js-mainbanner-container', function () {
        const link = $(this).attr('data-href-link');
        if (link) {
            window.location.href = link;
        }
    });
};

Slider.initEinsteinMutationObserver = () => {
    const parentContainers = document.querySelectorAll('div[id^="cq_"], .js-einstein-slider');
    if (!parentContainers.length) return;
    const config = {
        childList: true,
        subtree: true
    };
    for (let i = 0; i < parentContainers.length; i++) {
        const parentContainer = parentContainers[i];
        const callback = (mutationList, observer) => {
            Slider.initCarousels();
            observer.disconnect();
        };
        const observer = new MutationObserver(callback);
        observer.observe(parentContainer, config);
    }
};

module.exports = {
    init: $(document).ready(function () {
        Slider.initCarousels();
        Slider.initMainBannerListener();
    }),
    initEinstein: $(document).ready(function () {
        Slider.initEinsteinMutationObserver();
    })
};
