'use strict';
/* globals dataLayer */

var PAGE_GROUP = {
    HOME: 'Home',
    CATEGORY_LANDING: 'Category Landing',
    PRODUCT_LISTING: 'Product Listing',
    PRODUCT_DETAIL: 'Product Detail',
    SEARCH: 'Search',
    WISHLIST: 'Wishlist',
    CHECKOUT: 'Checkout',
    ACCOUNT: 'Account',
    STORE: 'Store',
    CUSTOMER_SERVICE: 'Customer Service',
    OTHER: 'Other'
};

/**
 * Pushes the event object to the data layers if they exist.
 * @param {Object} event - The event object to be pushed to the data layers.
 */
function pushToDataLayer(event) {
    if ('dataLayer' in window) {
        dataLayer.push(event);
    }
}

/**
 * Cleans an object of all undefined properties.
 * @param {Object} obj - The object to clean.
 *
 * @return {Object} - The cleaned object.
 */
function cleanObject(obj) {
    var objectToClean = obj;
    Object.keys(objectToClean).forEach(function (key) {
        if (objectToClean[key] && typeof objectToClean[key] === 'object') {
            cleanObject(objectToClean[key]);
        } else if (obj[key] == null) delete objectToClean[key];

        if (objectToClean && !isNaN(objectToClean.quantity)) {
            objectToClean.quantity = Number(objectToClean.quantity);
        }
    });
    return obj;
}

/**
 * Fetches the current pagegroup.
 *
 * @return {string} - The page group.
 */
function getPageGroup() {
    var $page = $('.page');

    if ($page.length > 0) {
        var action = $page.data('action');
        switch (action) {
            case 'Home-Show':
                return PAGE_GROUP.HOME;
            case 'Search-Show':
                var queryString = $page.data('querystring');
                if (queryString && queryString.indexOf('cgid') >= 0) {
                    return PAGE_GROUP.PRODUCT_LISTING;
                }

                return PAGE_GROUP.SEARCH;
            case 'Product-Show':
            case 'Product-ShowInCategory':
                return PAGE_GROUP.PRODUCT_DETAIL;
            case 'ProductLists-Detail':
                return PAGE_GROUP.WISHLIST;
            case 'Cart-Show':
            case 'Checkout-Begin':
            case 'Checkout-Login':
            case 'Order-Confirm':
                return PAGE_GROUP.CHECKOUT;
            case 'Register-Show':
            case 'Login-Show':
            case 'Order-History':
            case 'Order-Details':
            case 'Address-List':
            case 'PaymentInstruments-List':
                return PAGE_GROUP.ACCOUNT;
            case 'Stores-Find':
            case 'Stores-Detail':
                return PAGE_GROUP.STORE;
            case 'Account-ContactUs':
                return PAGE_GROUP.CUSTOMER_SERVICE;
            default:
                break;
        }

        if (action && action.indexOf('Account') >= 0) {
            return PAGE_GROUP.ACCOUNT;
        }
    }
    return PAGE_GROUP.OTHER;
}

/**
 * Gets the current page breadcrumb path
 *
 * @returns {string} - The breadcrumb path;
 */
function getBreadCrumbPath() {
    var breadCrumb = $('.breadcrumb').data('path');
    var breadCrumbList = [];


    if (breadCrumb) {
        breadCrumbList = breadCrumb.split('/');
        breadCrumbList.shift();

        return breadCrumbList.join('/');
    }

    return '';
}

/**
 * Determines the list element for the analytics JSON.
 * @param {jQuery} $element - The analytics element.
 *
 * @return {string} - The list.
 */
function determineList($element) {
    var list = getPageGroup();

    var $contentList = $element.closest('[data-list-id]');

    if ($contentList && $contentList.length > 0) {
        var listID = $contentList.data('list-id');
        list += ' - ' + listID;
    }

    return list;
}

/**
 * Process a single porduct item.
 * @param {number} index - Current item index
 * @param {jQuery} analyticsElement - Current analytics element
 * @param {Object} ecommerceData - Data object
 * @param {Object} detailEcommerceData - Detail object
 */
function processSingleImpressionProduct(
    index, analyticsElement, ecommerceData) {
    var jsonString = $(analyticsElement).attr('content');

    if (jsonString && jsonString.length > 0) {
        var jsonData = JSON.parse(jsonString);
        jsonData.position = index;

        var compiledList = determineList($(analyticsElement));

        if (compiledList) {
            jsonData.list = compiledList;

            if (compiledList === PAGE_GROUP.PRODUCT_LISTING) {
                jsonData.list += ' - ' + getBreadCrumbPath();
            }

            ecommerceData.ecommerce.impressions.push(cleanObject(jsonData));
        }
    }
}

/**
 * Pushes a product click to the data layer.
 * @param {jQuery} $element - The clicked element.
 */
function pushProductClick($element) {
    var $analyticsElement = $element.find('meta[itemprom=analytics]').first();
    var jsonString = $analyticsElement.attr('content');
    var list = determineList($analyticsElement);

    if (list && jsonString && jsonString.length > 0) {
        var jsonObject = JSON.parse(jsonString);
        jsonObject.position = $element.data('position');

        if (list === PAGE_GROUP.PRODUCT_LISTING) {
            list += ' - ' + getBreadCrumbPath();
        }

        var ecommerceData = {
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: {
                        list: list,
                        action: 'click'
                    },
                    products: [
                        cleanObject(jsonObject)
                    ]
                }
            }
        };

        pushToDataLayer(ecommerceData);
    }
}

/**
 * Pushes a product detail view to the data layer.
 * @param {jQuery} $element - The clicked element.
 */
function pushProductDetailView($element) {
    var $analyticsElement = $element.find('meta[itemprom=analytics]').first();

    var jsonString = $analyticsElement.attr('content');
    var list = determineList($analyticsElement);
    if (list && jsonString && jsonString.length > 0) {
        var jsonObject = JSON.parse(jsonString);

        var ecommerceData = {
            event: 'productDetail',
            ecommerce: {
                detail: {
                    actionField: {
                        list: list
                    },
                    products: [
                        cleanObject(jsonObject)
                    ]
                }
            }
        };
        pushToDataLayer(ecommerceData);
    }
}
/**
 * Pushes a product remarkting tag
 * @param {jQuery} $element - The clicked element.
 */
function pushProductRemarkting($element) {
    var $analyticsElement = $element.find('meta[itemprom=analytics]').first();
    var jsonString = $analyticsElement.attr('content');

    var list = determineList($analyticsElement);
    if (list && jsonString && jsonString.length > 0) {
        var jsonObject = JSON.parse(jsonString);

        var ecommerceData = {
            event: 'fireRemarketingTag_product',
            google_tag_params: {
                ecomm_pagetype: 'product',
                ecomm_proid: jsonObject.id,
                ecomm_totalvalue: jsonObject.price,
                ecomm_name: jsonObject.name,
                ecomm_pvalue: jsonObject.price,
                ecomm_pbrand: jsonObject.brandName
            }
        };
        pushToDataLayer(ecommerceData);
    }
}

/**
 * Pushes a product add to basket to the data layer.
 * @param {jQuery} $element - The clicked element.
 * @param {number} quantityChange - The quantity
 */
function pushAddToBasket($element, quantityChange) {
    var $analyticsElement = $element.find('meta[itemprom=analytics]').first();
    var jsonString = $analyticsElement.attr('content');

    if (jsonString && jsonString.length > 0) {
        var jsonObject = JSON.parse(jsonString);

        jsonObject.quantity = Number(quantityChange) || 1;

        var ecommerceData = {
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'EUR',
                add: {
                    products: [
                        cleanObject(jsonObject)
                    ]
                }
            }
        };

        pushToDataLayer(ecommerceData);
    }
}

/**
 * Pushes a product add to basket to the data layer.
 * @param {jQuery} $element - The clicked element.
 * @param {number} quantityChange - The quantity
 * @param {string} analytics - The analytics
 */
function pushRemoveFromBasket($element, quantityChange, analytics) {
    var $analyticsElement = $element.find('meta[itemprom=analytics]').first();
    var jsonString = analytics || $analyticsElement.attr('content');
    if (jsonString && jsonString.length > 0) {
        var jsonObject = JSON.parse(jsonString);
        jsonObject.quantity = Number(quantityChange) || Number($element.find('.quantity-form .quantity').val());
        var ecommerceData = {
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: [
                        cleanObject(jsonObject)
                    ]
                }
            }
        };

        pushToDataLayer(ecommerceData);
    }
}

/**
 * A function to handle a click leading to a checkout option selection.
 * @param {number} step - The checkout step
 * @param {string} checkoutOption - The chckout option
 */
function onCheckoutOption(step, checkoutOption) {
    const ecommerceData = {
        event: 'checkoutOption',
        ecommerce: {
            checkout_option: {
                actionField: {
                    step: step,
                    option: checkoutOption
                }
            }
        }
    };
    pushToDataLayer(ecommerceData);
}

/**
 * A function to handle a click leading to a checkout option selection.
 * @param {number} step - The checkout step
 */
function onCheckoutStepChange(step) {
    var ecommerceData = {
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: {
                    step: step
                },
                products: []
            }
        }
    };

    $('.product[data-pid] meta[itemprom=analytics], .product-info meta[itemprom=analytics], .cart-product-card  meta[itemprom=analytics], #checkout-main .product-line-item  meta[itemprom=analytics]').each(function (index, trackingItem) {
        var $trackingItem = $(trackingItem);
        var jsonString = $trackingItem.attr('content');

        if (jsonString && jsonString.length > 0) {
            var jsonObject = JSON.parse(jsonString);

            ecommerceData.ecommerce.checkout.products.push(
                cleanObject(jsonObject)
            );
        }
    });

    pushToDataLayer(ecommerceData);
}

/**
 * Pushes product impressions to the data layer. This function will be executed when intersection observer is not supported
 */
function pushProductImpressions() {
    var ecommerceData = {
        event: 'productImpression',
        ecommerce: {
            impressions: []
        }
    };

    $('.product[data-pid] meta[itemprom=analytics]').each(function (index, analyticsElement) {
        processSingleImpressionProduct(index, analyticsElement, ecommerceData);
    });

    if (ecommerceData.ecommerce.impressions.length > 0) {
        pushToDataLayer(ecommerceData);
    }
}

/**
 * Push product impressions to data layer when viewed.
 * @param {[IntersectionObserverEntry]} entries - Observer entries
 * @param {IntersectionObserver} observer - The observer
 */
function pushObservableProductImpressions(entries, observer) {
    var ecommerceData = {
        event: 'productImpression',
        ecommerce: {
            impressions: []
        }
    };

    entries.forEach(function (entry) {
        if (entry.intersectionRatio <= 0) {
            return;
        }
        var analyticsElement = $(entry.target).find('meta[itemprom=analytics]').first();

        processSingleImpressionProduct(
            $(entry.target).data('index'), analyticsElement, ecommerceData
        );

        observer.unobserve(entry.target);
    });

    if (ecommerceData.ecommerce.impressions.length > 0) {
        pushToDataLayer(ecommerceData);
    }
}

/**
 * Add the intersection observer to the product tile DOM elements loaded.
 * When the elements become visible, the intersection observer will execute.
 * @param {NodeList} nodes - the node list containing the product tile elements
 * @param {number} index - The position of the product
 * @param {IntersectionObserver} intersectionObserver - The intersection observer
 */
function initObservableProductImpressions(nodes, index, intersectionObserver) {
    let i = index;
    if (nodes.length > 0) {
        nodes.forEach((node) => {
            let $productChild;
            let $node = $(node);

            // Determine if node itself is the product tile
            if ($node.closest('.product[data-pid]') && $node.closest('.product[data-pid]').length > 0) {
                $productChild = $node.closest('.product[data-pid]');
            } else {
                $productChild = $node.find('.product[data-pid]');
            }

            for (let childsIndex = 0; childsIndex < $productChild.length; childsIndex++) {
                const productChild = $productChild[childsIndex];
                if (!$(productChild).data('index')) {
                    $(productChild).data('index', i);
                }
                intersectionObserver.observe(productChild);
                i++;
            }
        });
    }
}

module.exports = {
    initPageGroups: function () {
        const event = {
            page_group: getPageGroup()
        };
        pushToDataLayer(event);
    },
    initProductImpressions: function () {
        if ('IntersectionObserver' in window) {
            try {
                const intersectionObserver = new IntersectionObserver(function (entries) {
                    pushObservableProductImpressions(entries, intersectionObserver);
                }, {
                    threshold: [0.75]
                });


                $('.product[data-pid]').each(function (index, trackingItem) {
                    if (!$(trackingItem).data('index')) {
                        $(trackingItem).data('index', index + 1);
                    }
                    intersectionObserver.observe(trackingItem);
                });

                const mutationConfig = { childList: true };
                // List all target containers that need to be watched. Every $('.product[data-pid]') will be observed
                const mutationTargets = [
                    ...document.querySelectorAll('.product-grid'),
                    ...document.querySelectorAll('.product-slider'),
                    ...document.querySelectorAll('.fullscreen-pop-up .content')
                ];
                const mutationObserver = new MutationObserver(function (mutations) {
                // Loop the mutations that are triggered defined in mutationTargets
                    mutations.forEach(function (mutation) {
                    // When clicking more button, add the current loaded items to the 'position' attribute (specific for lister)
                        const pageSize = $('.grid-footer').data('page-size') || 0;
                        const pageNumber = $('.grid-footer').data('page-number') || 0;
                        let currentLoadedItems = pageSize * pageNumber;

                        // Async page load (more button, filters, sort) - observe loaded product tiles
                        const nodes = mutation.addedNodes;
                        initObservableProductImpressions(nodes, currentLoadedItems + 1, intersectionObserver);
                    });
                });

                if (mutationTargets && mutationTargets.length > 0) {
                    mutationTargets.forEach(function (mutationTarget) {
                    // Initial page load - observe loaded product tiles
                        const nodes = mutationTarget.childNodes;
                        initObservableProductImpressions(nodes, 1, intersectionObserver);

                        // Observe further added product-tiles
                        mutationObserver.observe(mutationTarget, mutationConfig);
                    });
                }
            } catch (error) {
                // DO NOTHING
            }
        } else {
            // Execute product impressions when intersection observer is not supported
            pushProductImpressions();

            $(document).on('search:showMore', function () {
                pushProductImpressions();
            });
        }
    },
    initProductClicks: function () {
        $(document).on('click', '.product[data-pid], .product-info[data-pid], .product-tile[data-pid]', function (e) {
            const $target = $(e.target);

            // Otherwise send click event when clicked on link
            if (!$target.hasClass('quickview') && !$target.hasClass('fa-heart') && $target.closest('a') && $target.closest('a').length > 0) {
                pushProductClick($(this));
            }

            return true;
        });
    },
    initProductDetailView: function () {
        const $productDetailView = $('.product-detail');
        if ($productDetailView && $productDetailView.length > 0) {
            pushProductDetailView($productDetailView);
            pushProductRemarkting($productDetailView);
        }
    },
    initAddToCart: function () {
        $(document).on('product:afterAddToCart', function (event) {
            var $productContainer = $(event.target).find('.product-detail, .product-tile');
            pushAddToBasket($productContainer, $productContainer.find('.quantity-select').val());
        });
    },
    initRemoveFromCart: function () {
        $('body').on('product:afterRemoveFromCart', function (event, data) {
            var $lineItemContainer = $('.uuid-' + data.uuid);
            pushRemoveFromBasket($lineItemContainer, null, data && data.analytics);
        });
    },
    initChangeCartAmount: function () {
        $(document).on('change', '.line-item-quantity .quantity-select', function () {
            var previousAmount = Number($(this).data('pre-select-qty'));
            var change = Number($(this).val()) - previousAmount;

            if (change < 0) {
                pushRemoveFromBasket($(this).closest('.cart-product-card'), Math.abs(change));
            } else {
                pushAddToBasket($(this).closest('.cart-product-card'), change);
            }

            $(this).data('pre-select-qty', $(this).val());
        });
    },
    initCheckoutSteps: function () {
        $(document).on('checkout:loadStage', function (e, currentStage) {
            onCheckoutStepChange(currentStage);
        });

        $(document).on('checkout:switchStage', function (e, stage) {
            if (stage.option) {
                onCheckoutOption(stage.currentStage, stage.option);
            }
        });

        var $page = $('.page');
        if ($page.data('action') === 'Cart-Show') {
            onCheckoutStepChange(1);
        } else if ($page.data('action') === 'Checkout-Login') {
            onCheckoutStepChange(2);
        }
    }
};
