'use strict';

var cart = require('../cart/cart');
var realTimeStock = require('../cart/realTimeStock');

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('mouseenter', function () {
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            var url = $('.minicart').data('action-url');
            var count = parseInt($('.minicart .minicart-quantity').text(), 10);

            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                $('.minicart .popover').addClass('show');
                $('.minicart .popover').spinner().start();
                $.get(url, function (data) {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    $.spinner().stop();
                    realTimeStock.parseRealtimeStock($('#minicart-realtimeStockInfo').data('stock'));
                });
            }
        }
    });

    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });

    $('.minicart').on('mouseleave', function () {
        $('.minicart .popover').empty();
        $('.minicart .popover').removeClass('show');
    });
};
