'use strict';

var base = require('../product/base');
var focusHelper = require('../base/components/focus');
const realTimeStock = require('./realTimeStock');

var debounce = require('lodash/debounce');
/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Updates and refactors the callout messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 * @param {Object} items - items inside the cart
 */
function updateCalloutMessages(approachingDiscounts, items) {
    $('.cart__callouts div').remove();
    var messages = [];

    if (approachingDiscounts && approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (discount) {
            var message = `<div>${discount.discountMsg}</div>`;
            if (!messages.includes(message)) {
                messages.push(message);
            }
        });
    }
    if (items && items.length > 0) {
        items.forEach(function (item) {
            if (item.appliedPromotions && item.appliedPromotions.length > 0) {
                item.appliedPromotions.forEach(function (promotion) {
                    $.ajax({
                        url: $('.js-linkToAsset').data('url') + `?contentAssetID=${promotion.contentAssetID}`,
                        method: 'GET',
                        dataType: 'html',
                        success: function (data) {
                            var temp = JSON.parse(data);
                            var message = $.parseHTML(temp.html);

                            if (message && message !== '' && !messages.includes(message[0].innerHTML)) {
                                messages.push(message[0].innerHTML);
                                var $div = $('<div></div>');
                                $div.append(message);
                                $('.cart__callouts').append($div);
                            }
                        }
                    });
                });
            }
        });
    }
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (!data.valid.message) {
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }
        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
        $('.cart-error-show').addClass('d-none');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.minicart-quantity').empty().append(data.numItems);
    if (data.isATCOrder) {
        $('.js-sub-total-container').remove();
        $('.grand-total').remove();
    } else {
        $('.sub-total').empty().append(data.totals.subTotal);
        $('.grand-total').empty().append(data.totals.grandTotal);
    }
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    data.shipments[0].shippingMethods.forEach(shipment => {
        if (shipment.shippingCost !== '€ 0,00' && shipment.shippingCost !== '0,00 €') {
            $('.' + shipment.ID).empty().append(shipment.shippingCost);
        } else {
            $('.' + shipment.ID).empty().append($('.shipping-cost').data('labelfree'));
        }
    });

    if (data.totals.shippingValue.value !== 0) {
        $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    } else {
        $('.shipping-cost').empty().append($('.shipping-cost').data('labelfree'));
    }

    if (data.totals.orderLevelDiscountTotal.value > 0.0) {
        $('.order-discount').removeClass('d-none');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('d-none');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0.0) {
        $('.shipping-discount').removeClass('d-none');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('d-none');
    }

    data.items.forEach(function (item) {
        $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        $('.line-item-price-' + item.UUID).empty().append(item.renderedPrice);
        item.discountLineItems.forEach(function (discountLineItem) {
            if (discountLineItem.hasAvailableBonusProducts) {
                $('.bonus-product-button[data-duuid="' + discountLineItem.uuid + '"]').show();
            } else {
                $('.bonus-product-button[data-duuid="' + discountLineItem.uuid + '"]').hide();
            }
        });
    });
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability && lineItem.availability.messages) {
        if (!lineItem.available) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<span class="border-red rounded line-item-instock not-available text-nowrap px-2 py-1">'
                    + '<i class="fas fa-times text-red"></i> '
                    + message
                    + '</span>';
            });
        } else {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<span class="border-green rounded line-item-instock available text-nowrap px-2 py-1">'
                    + '<i class="fas fa-check text-green"></i> '
                    + message
                    + '</span>';
            });
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid;
            var newColor = 'Color: ' + colorAttr.displayValue;
            $(colorSelector).text(newColor);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid;
            var newSize = 'Size: ' + sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var imageSelector = '.card.product-info.uuid-' + uuid + ' .item-image > img';
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Updates Promo of the cart
 * @param {Object} data - AJAX response from the server
 */
function validatePromos(data) {
    $('.discount-error').addClass('d-none');
    for (let index = 0; index < data.length; index++) {
        if (!data[index].applied && data[index].type === 'coupon') {
            $('.discount-error').removeClass('d-none');
            $('.discount-error').text(data[index].details);
        }
    }
}

/**
 * Updates Promo of the cart
 * @param {Object} data - AJAX response from the server
 */
function updatePromos(data) {
    validatePromos(data.totals.discounts);
    data.items.forEach(element => {
        var messages = '';

        if (element.appliedPromotions) {
            element.appliedPromotions.forEach(promotion => {
                messages += promotion.name + '<br>';
            });
        }

        $('.applied-promotions-' + element.UUID).html('<strong>' + messages + '</strong>');
    });
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog  modal-dialog-centered quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body h-100></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 * @param {boolean} hasbonusproduct - has bonus product
 * @param {boolean} isbonusproduct Is a removal of a bonus product?
 * @param {Object} analytics Is a removal of a bonus product?
 */
function confirmDelete(actionUrl, productID, productName, uuid, hasbonusproduct, isbonusproduct = false, analytics) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');
    var $elemHasBonusProduct = $('#product-has-bonus');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);
    $deleteConfirmBtn.data('isbonusproduct', isbonusproduct);
    $deleteConfirmBtn.data('analytics', analytics);

    $elemHasBonusProduct.hide();
    if (hasbonusproduct) {
        $elemHasBonusProduct.show();
    }

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Display error message
 * @param {string} message - ajax response message
 */
function handlePostCartAdd(message) {
    // show add to cart toast
    if ($('.add-to-cart-messages-incart').length === 0) {
        $('body').append(
            '<div class="add-to-cart-messages-incart"></div>'
        );
    }

    $('.add-to-cart-messages-incart').append(
        '<div class="alert alert-danger add-to-basket-alert text-center" role="alert">'
        + message
        + '</div>'
    );

    setTimeout(function () {
        $('.add-to-basket-alert').remove();
    }, 5000);
}

/**
 * Updates deliveryTime of cart
 * @param {Object} deliveryTime - AJAX response from the server
 */
function updateDeliveryTime(deliveryTime) {
    $('.delivery-time').empty().append(deliveryTime);
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        var hasbonusproduct = $(this).data('hasbonusproduct');
        var isBonusProduct = ($(this).data('isbonusproduct') === 'true' || $(this).data('isbonusproduct') === true);
        var analytics = $(this).data('analytics');
        confirmDelete(actionUrl, productID, productName, uuid, hasbonusproduct, isBonusProduct, analytics);
    });

    $('body').on('product:afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid, data.analytics);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var isBonusProduct = $(this).data('isbonusproduct');
        var analytics = $(this).data('analytics');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('body').trigger('product:afterRemoveFromCart', { uuid: uuid, analytics: analytics });
                if (data.basket.items.length === 0) {
                    $('.cart').empty();
                    $('.js-cart-container').empty().append('<div class="col-12"> ' +
                        '<h3 class="h3 mb-5">' + data.basket.resources.emptyCartMsg + '</h3> ' +
                        '</div>'
                    );
                    $('.cart-page').remove();
                    $('.cart-header').remove();
                    $('.cart-error-show').remove();
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    $('.bonus-product-button[data-pliuuid=' + uuid + ']').remove();
                    $('.bundled-line-item[data-pliuuid=' + uuid + ']').remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    updateDeliveryTime(data.basket.deliveryTimeHtml);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }
                // Update promos
                updatePromos(data.basket);

                $('body').trigger('cart:update');
                if (isBonusProduct) {
                    location.reload();
                    return;
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    $.spinner().stop();
                }
            }
        });
    });

    /**
     * re-renders the order totals and the number of items in the cart
     * @param {Object} message - Error message to display
     */
    function createErrorNotification(message) {
        var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
            'fade show" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span>' +
            '</button>' + message + '</div>';

        $('.cart-error').append(errorHtml);
    }

    const modalCartQuantityAlert = $('#cartProductQuantityAlert');
    // eslint-disable-next-line require-jsdoc
    function updateCartQuantity({ uuid, quantity, url, preSelectQty, qtyElem }) {
        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                const dataItem = data.items.find(item => item.UUID === uuid);
                const elem = $('.quantity[data-uuid="' + uuid + '"]');
                if (dataItem) {
                    // set the quantity received from the backend, because there can be a maximum allowed quantity
                    elem.val(dataItem.quantity);
                }

                if (data.showconfirmremovebonus) {
                    modalCartQuantityAlert.modal('show');
                    modalCartQuantityAlert.data('qtyElem', qtyElem.attr('id'));
                    modalCartQuantityAlert.data('uuid', qtyElem.data('uuid'));
                    modalCartQuantityAlert.data('quantity', quantity);
                    modalCartQuantityAlert.data('productId', qtyElem.data('productid'));
                    modalCartQuantityAlert.data('url', qtyElem.data('action'));
                }

                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updatePromos(data);
                updateAvailability(data, uuid);
                // enable/disable submit button
                validateBasket(data);
                // print stock warning again
                realTimeStock.parseRealtimeStock(data.realtimeStock);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update');

                $.spinner().stop();
                var thisQtyElem = qtyElem;
                var elemParent = thisQtyElem.parents('.product-info');
                var hasClassElem = elemParent.hasClass('bonus-product-line-item');
                var $cart = $('.cart-page');
                if (data.newBonusDiscountLineItem
                    && Object.keys(data.newBonusDiscountLineItem).length !== 0) {
                    base.methods.editBonusProducts(data.newBonusDiscountLineItem);
                } else if (hasClassElem && $cart.length && !data.showconfirmremovebonus) {
                    $.spinner().start();
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    handlePostCartAdd(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    }

    var debounceSuggestions = debounce(updateCartQuantity, 200);

    $('body').on('click', '#btn-cart-quantityalert-confirm', function () {
        const qtyElemId = modalCartQuantityAlert.data('qtyElem');
        const quantity = modalCartQuantityAlert.data('quantity');
        const uuid = modalCartQuantityAlert.data('uuid');
        var qtyElem = $('#' + qtyElemId);
        var url = modalCartQuantityAlert.data('url');
        var productID = modalCartQuantityAlert.data('productId');


        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid,
            approveremovebonus: true
        };
        url = appendToUrl(url, urlParams);

        updateCartQuantity({ uuid, quantity, url, qtyElem });
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        // send quantity update
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var maxValue = parseInt($(this).attr('data-maxvalue'), 10);

        if (quantity > 1 && quantity > maxValue) {
            quantity = maxValue;
        }

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
        var qtyElem = $(this);
        debounceSuggestions({ uuid, quantity, url, preSelectQty, qtyElem });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':checked').attr('data-shipping-id')
        };

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    }
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').removeClass('d-block');
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').removeClass('d-block');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').addClass('d-block');
                } else {
                    if (data.hasBonusProduct) {
                        location.reload();
                    }

                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    updatePromos(data);
                    location.reload();
                    return;
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();
        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var url = $(this).data('action');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if ($('.isBonusProductLineItem').length > 0 && data) {
                    location.reload();
                }

                $('.coupon-uuid-' + uuid).fadeOut(function () {
                    $(this).remove();
                });
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateCalloutMessages(data.approachingDiscounts, data.items);
                validateBasket(data);
                updatePromos(data);
                location.reload();
                return;
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.coupon-code-field', function () {
        var fieldChanged = this;
        $('.coupon-code-field').each(function () {
            $(this).val($(fieldChanged).val());
        });
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity
        };

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    window.onload = function () {
        if (!window.sessionStorage.getItem('refreshedForBonusProduct')) {
            $.ajax({
                url: $('#refreshedForBonusProduct').data('url'),
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    if (data.bonusDiscountLineItem) {
                        base.methods.editBonusProducts(data);
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
            window.sessionStorage.setItem('refreshedForBonusProduct', true);
        }
    };

    if ($('#discountError').data('discounts')) {
        validatePromos($('#discountError').data('discounts'));
    }

    $(document).on('click', '.js-cart-add-prescription', function () {
        const $prescriptionContainer = $(this).parents('.js-prescription-container');
        const productID = $prescriptionContainer.attr('data-product-id');
        const uuid = $prescriptionContainer.attr('data-uuid');
        const prescriptionNr = $prescriptionContainer.attr('data-prescription-number');

        const $addPrescriptionModal = $('#addPrescriptionModal');
        $addPrescriptionModal.attr('data-product-id', productID);
        $addPrescriptionModal.attr('data-uuid', uuid);

        const $prescriptionNumberField = $addPrescriptionModal.find('input[name=prescriptionNumber]');
        if (prescriptionNr) {
            $prescriptionNumberField.val(prescriptionNr);
        } else {
            $prescriptionNumberField.val('');
        }

        $addPrescriptionModal.modal('show');
    });

    $(document).on('submit', '.js-prescription-form', function (e) {
        e.preventDefault();
        const $addPrescriptionModal = $('#addPrescriptionModal');
        $addPrescriptionModal.spinner().start();

        const uuid = $addPrescriptionModal.attr('data-uuid');
        const productID = $addPrescriptionModal.attr('data-product-id');
        var url = $(this).data('action');

        const prescriptionNr = $(this).find('input[name=prescriptionNumber]').val();

        var urlParams = {
            pid: productID,
            uuid: uuid,
            prescriptionNr: prescriptionNr
        };
        url = appendToUrl(url, urlParams);

        $.ajax({
            url: url,
            type: 'get',
            success: function (res) {
                $addPrescriptionModal.modal('hide');
                $(`.js-prescription-container[data-uuid="${uuid}"]`).replaceWith(res.template);
                $('.js-missing-prescription-info').toggleClass('d-none', !res.basket.hasMissingPrescriptions);
                $addPrescriptionModal.spinner().stop();
            },
            error: function (res) {
                $addPrescriptionModal.modal('hide');
                createErrorNotification(res.responseJSON.errorMessage);
                $addPrescriptionModal.spinner().stop();
            }
        });
    });

    $(document).on('click', '.js-delete-prescription', function () {
        const $prescriptionContainer = $(this).parents('.js-prescription-container');
        const productID = $prescriptionContainer.attr('data-product-id');
        const uuid = $prescriptionContainer.attr('data-uuid');
        var url = $prescriptionContainer.attr('data-url');

        var urlParams = {
            pid: productID,
            uuid: uuid,
            delete: true
        };
        url = appendToUrl(url, urlParams);

        $.ajax({
            url: url,
            type: 'get',
            success: function (res) {
                $(`.js-prescription-container[data-uuid="${uuid}"]`).replaceWith(res.template);
                $('.js-missing-prescription-info').toggleClass('d-none', !res.basket.hasMissingPrescriptions);
            },
            error: function (res) {
                createErrorNotification(res.responseJSON.errorMessage);
            }
        });
    });

    // eslint-disable-next-line require-jsdoc
    function init() {
        realTimeStock.parseRealtimeStock($('#realtimeStockInfo').data('stock'));
    }
    init();

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.removeBonusProductInCart();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
