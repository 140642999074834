'use strict';

var errorIndex = 0;

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
    $(form).find('.custom-control-input.is-invalid').removeClass('is-invalid');
}

/**
 * password Confirm
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function passwordConfirm(form) {
    if (form[0].className === 'registration') {
        var confirm = '#registration-form-password-confirm, #completion-form-password-confirm';
        if ($('#registration-form-password, #completion-form-password').val() !== $(confirm).val()) {
            $(confirm).addClass('is-invalid');
            $(confirm).parents('.form-group').find('.invalid-feedback').text($(confirm).data('confirm-error'));
        }
    }
}

/**
 * scroll
 * @param {element} this_ - Form to be cleared
 * @returns {void}
 */
function scrollTo(this_) {
    if (errorIndex === 0) {
        var height = $(window).scrollTop();
        var offset = $(this_).offset().top;

        if (height > offset) {
            $('html, body').animate({
                scrollTop: $(this_).offset().top - 35
            }, 500, 'linear');
        }
        $(this_).focus();

        errorIndex = 1;
    }
}

module.exports = {
    invalid: function () {
        $('form input, form select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.badInput && $(this).data('bad-input')) {
                    validationMessage = $(this).data('bad-input');
                }
                if (this.validity.customError && $(this).data('custom-error')) {
                    validationMessage = $(this).data('custom-error');
                }
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.typeMismatch && $(this).data('type-mismatch')) {
                    validationMessage = $(this).data('type-mismatch');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                if (this.validity.stepMismatch && $(this).data('step-mismatch')) {
                    validationMessage = $(this).data('step-mismatch');
                }

                $(this).parents('.form-group').find('.invalid-feedback').text(validationMessage);

                scrollTo(this);
            }
        });
    },

    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            errorIndex = 0;

            // clear all errors when trying to submit the form
            var form = $(this).parents('form');
            clearForm(form);

            passwordConfirm(form);
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
};
