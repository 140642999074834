'use strict';

module.exports = {
    init: $(document).ready(function () {
        $('.js-readmore').each(function () {
            if ($(this).prop('scrollHeight') > $(this).height()) {
                $(this).find('.readmore-button-open').show();
            }
        });
    }),
    readmore: function () {
        $('.container').on('click', '.readmore-button', function () {
            $(this).parent().toggleClass('readmore-closed readmore-open');
        });
    }
};
