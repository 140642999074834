'use strict';

var $wishlistCounter = $('.js-wishlistCount');

/**
 * toggle the products in the wishlist count productlist
 * @param {string} pid - product sku
 */
function toggleProductInWishlist(pid) {
    $.spinner().stop();

    /**
     * @type {Array}
     */
    let products = $wishlistCounter.data('products');
    if (products.indexOf(pid) < 0) {
        products.push(pid);
    } else {
        products.splice(products.indexOf(pid), 1);
    }

    $wishlistCounter.data('products', products);
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon) {
    if (data.success) {
        toggleProductInWishlist(data.pid);

        icon.toggleClass('active').find('i').toggleClass('d-none');
        $('.add-to-wishlist').remove();
        if ($('.add-to-wishlist').length === 0) {
            $('body').append(
                '<div class="add-to-wishlist"></div>'
            );
        }
        if (icon.hasClass('active')) {
            $('.add-to-wishlist').append(
                '<div class="alert alert-success add-to-basket-alert text-center" role="alert">'
                + data.msg
                + '</div>'
            );
        } else {
            $('.add-to-wishlist').append(
                '<div class="alert alert-success add-to-basket-alert text-center" role="alert">'
                + data.msg
                + '</div>'
            );
        }
    }
}

module.exports = {
    processWishlistClick: function () {
        $('body').on('click', '.js-wishlistTile', function (e) {
            e.preventDefault();

            var $this = $(this);
            var url = $this.hasClass('active') ? $this.data('removeurl') : $this.attr('href');
            var pid = $this.data('pid');

            var $heart = $(`.js-wishlistTile[data-pid="${pid}"]`);
            if (!url || !pid) {
                return;
            }

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid
                },
                success: function (data) {
                    displayMessageAndChangeIcon(data, $heart);
                    const $productCard = $('.product-info:has(> div[data-pid="' + pid + '"])');
                    if ($productCard.length && $productCard.closest('.wishlistItemCards').length) {
                        $productCard.remove();
                    }
                },
                error: function (err) {
                    displayMessageAndChangeIcon(err, $heart);
                },
                complete: function () {
                    setTimeout(function () {
                        $('.add-to-wishlist').remove();
                    }, 5000);
                }
            });
        });
    },
    initProducts: function () {
        var products = $wishlistCounter.data('products');
        if (products) {
            if (typeof products === 'string') {
                products = JSON.parse(products);
            }

            products.forEach(function (productSku) {
                $(`.js-wishlistTile[data-pid="${productSku}"]`).addClass('active').find('i').toggleClass('d-none');
            });
        }
    }
};
