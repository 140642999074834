'use strict';

module.exports = {
    init: function () {
        $(document).ready(function () {
            if (window.self === window.top && $('#modal-languagechoice')) {
                $('#modal-languagechoice').modal('show');
            }
        });
    }
};
