/**
 * handles realtime stock information
 * @param {Object} stockInfo - realtime stock information
 */
function parseRealtimeStock(stockInfo) {
    if (stockInfo && Object.entries(stockInfo).length > 0) {
        for (const [id, stockItem] of Object.entries(stockInfo)) {
            const elem = $('.quantity[data-pid="' + id + '"]');

            if (stockItem) {
                // set the quantity received from the backend, because there can be a maximum allowed quantity
                // set max value of input field
                var maxvalue = parseInt(elem.attr('data-maxvalue'), 10);
                if (maxvalue > 0 && stockItem.maxQuantity < maxvalue) {
                    elem.attr('data-maxvalue', stockItem.maxQuantity);
                }

                if (!stockItem.stockIsAvailable) {
                    if (stockItem.stockQty > 0) {
                        var warningElem = $('.quantity-limit-text-' + stockItem.id);
                        warningElem.addClass('text-red-alert');
                        warningElem.addClass('font-weight-bold');
                        warningElem.html(stockItem.message);
                        warningElem.show();
                    } else {
                        elem.attr('disabled', true);
                        elem.parents('.quantity-Group').find('.btn-Quantity').attr('disabled', true);
                        $('.out-of-stock-' + stockItem.id).removeClass('d-none');
                    }
                }
            }
        }
    }
}


module.exports = {
    parseRealtimeStock: parseRealtimeStock
};
