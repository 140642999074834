'use strict';
const videojs = require('video.js').default;
require('videojs-youtube');

/**
 * This reads configuration from the video wrapper object.
 * @param {jQuery} $video The jquery video object.
 * @returns {Object} Config object.
 */
function getConfig($video) {
    if ($video.length <= 0) {
        return null;
    }

    return {
        videoUrl: $video.data('video') ? $video.data('video') : null,
        videoType: $video.data('videoType') ? $video.data('videoType') : null,
        autoplay: $video.data('autoplay') ? $video.data('autoplay') : false
    };
}

/**
 * This will build the source object.
 * @param {Object} config Config object.
 * @returns {Object} Source object.
 */
function getSource(config) {
    const defaultSource = { type: 'video/mp4', src: config.videoUrl };

    if (!config.videoType) {
        return defaultSource;
    }

    switch (config.videoType) {
        case 'YouTube':
            return { type: 'video/youtube', src: config.videoUrl };
        default:
            return defaultSource;
    }
}

/**
 * This will construct the tech order, which refers to specific tech to play the video.
 * @returns {Array<Object>} Tech order array.
 */
function getTechOrder() {
    return ['youtube', 'html5'];
}

/**
 * This builds the options object for the videojs player.
 * @param {Object} config Config object.
 * @returns {Object} options object.
 */
function buildOptions(config) {
    const source = getSource(config);
    const techOrder = getTechOrder();

    return {
        techOrder: techOrder,
        sources: [source],
        muted: config.autoplay,
        autoplay: config.autoplay
    };
}

/**
 * This will initialize all videojs players.
 */
function initializePlayers() {
    $('.video-js').each(function () {
        const videoId = $(this).attr('id');
        const config = getConfig($(this));
        const options = buildOptions(config);

        videojs(videoId, options);
    });
}

module.exports = {
    init: $(document).ready(function () {
        initializePlayers();
    })
};
