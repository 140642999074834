'use strict';

/**
* @param {Object} scope - Quantity input field DOM element
*/
function changeTrigger(scope) {
    try {
        $(scope).parent().find('.input-Quantity').trigger('change');
    } catch (error) {
        return;
    }
}

module.exports = {

    increaseQuantity: function () {
        $('body').on('change', 'input.input-Quantity', function () {
            // When the input value changes, make sure the user cannot fill in a number higher than the maximum amount.
            var val = $(this).val();
            var maxValue = parseInt($(this).attr('data-maxvalue'), 10);
            const productid = $(this).data('productid');
            const alwaysDisplayWarning = $(this).data('maxvaluewarningalwaysshow');
            const $parentElement = $(this).parents('.js-add-to-cart-group');
            const warningElem = $parentElement.length > 0 ? $parentElement.find('.quantity-limit-text-' + productid)
                : $('.quantity-limit-text-' + productid);
            warningElem.removeClass('text-red-alert');
            warningElem.removeClass('font-weight-bold');
            if (alwaysDisplayWarning === false) {
                warningElem.hide();
            }
            if (val >= maxValue) {
                if (productid) {
                    warningElem.addClass('text-red-alert');
                    warningElem.addClass('font-weight-bold');
                    warningElem.show();
                }
                $(this).val(maxValue);
            }
        });
        $('body').on('click', '.increase-Quantity', function () {
            const inputComponent = $(this).parent().find('.input-Quantity');
            inputComponent.val(function (i, val) {
                return ((val * 1) + 1);
            });
            changeTrigger(this);
        });
    },

    decreaseQuantity: function () {
        $('body').on('click', '.decrease-Quantity', function () {
            var input = $(this).parent().find('.input-Quantity');
            var maxValue = parseInt(input.attr('data-maxvalue'), 10);

            input.val(function (i, val) {
                if (val > 1) {
                    if (val > maxValue) {
                        return maxValue;
                    }
                    return ((val * 1) - 1);
                }
                return (val);
            });
            changeTrigger(this);
        });
    }

};
