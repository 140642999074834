var processInclude = require('./base/util');
var objectFitImages = require('object-fit-images');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./base/components/cookie'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/languagechoicepopup'));
    processInclude(require('./product/wishlist'));
    processInclude(require('./product/wishlistHeart'));

    processInclude(require('./components/miniCart'));
    processInclude(require('./components/quantity'));

    processInclude(require('./base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./base/components/countrySelector'));
    processInclude(require('./base/components/toolTip'));
    var tooltipInit = require('./components/tooltipInit');
    tooltipInit.tooltipRun();

    processInclude(require('./analytics/productAnalytics'));
    processInclude(require('./analytics/eventAnalytics'));

    processInclude(require('./components/slider'));
    processInclude(require('./video/video'));
    processInclude(require('./card/cardContainer'));
    processInclude(require('./chat/chat'));

    processInclude(require('./components/storeDelivery'));
    processInclude(require('./components/readmore'));

    require('./components/reserveAndGo').init();
    processInclude(require('./components/collapseArrow'));

    setTimeout(function () {
        objectFitImages();
    }, 0);
});

require('./components/spinner');
require('./util/polyfill');
