'use strict';

// var scrollAnimate = require('./scrollAnimate');

module.exports = function () {
    $(document).on('click', '.subscribe-email', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var emailId = $('input[name=hpEmailSignUp]').val();

        var messageSuccess = $(this).data('success-modal-message');
        var messageError = $(this).data('error-modal-message');

        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $.spinner().start();

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId
            },
            success: function (res) {
                $.spinner().stop();
                if (res.error === true) {
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-danger add-to-basket-alert text-center" role="alert">'
                        + messageError
                        + '</div>'
                    );
                } else {
                    $('input[name=hpEmailSignUp]').val('');
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-success add-to-basket-alert text-center" role="alert">'
                        + messageSuccess
                        + '</div>'
                    );
                }
                $(document).trigger('newsletter:subscribed');
            },
            error: function () {
                $.spinner().stop();
                $('.add-to-cart-messages').append(
                    '<div class="alert alert-danger add-to-basket-alert text-center" role="alert">'
                    + messageError
                    + '</div>'
                );
            },
            complete: function () {
                $.spinner().stop();
                setTimeout(function () {
                    $('.add-to-basket-alert').remove();
                }, 5000);
            }
        });
    });
};
