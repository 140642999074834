'use strict';

module.exports = {
    collapsibleArrow: function () {
        $('body').on('click', '.collapsible-arrow', function () {
            if (!$(this).find('i').hasClass('fa-rotate-180')) {
                $('i').removeClass('fa-rotate-180');
                $(this).find('i').addClass('fa-rotate-180');
            } else {
                $('i').removeClass('fa-rotate-180');
            }
        });
    }
};
