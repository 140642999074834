var Responsive = {};

Responsive.getBreakpoint = function (breakpoint) {
    var stringValue = getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-' + breakpoint);
    return parseInt(stringValue.replace(/(.*)px$/, '$1'), 10);
};

Responsive.isBelowBreakpoint = function (breakpoint) {
    return window.innerWidth < Responsive.getBreakpoint(breakpoint);
};

Responsive.isEqualOrBelowBreakpoint = function (breakpoint) {
    return window.innerWidth <= Responsive.getBreakpoint(breakpoint);
};

/**
 * Checks if the current browser window is in mobile view or not
 * @returns {boolean} The boolean result of the test
 */
Responsive.isMobile = function () {
    return Responsive.isEqualOrBelowBreakpoint('md');
};


/**
 * Checks if the current browser window is in mobile or tablet view or not
 * @returns {boolean} The boolean result of the test
 */
Responsive.isTabletOrSmaller = function () {
    return Responsive.isBelowBreakpoint('lg');
};

module.exports = Responsive;
